<template>
  <div>
    <el-drawer title="Search Filter" :visible.sync="drawerFilterVisible" direction="btt" size="50%">
      <div class="drawer">
        <el-form :ref="settings" label-width="100px" label-position="left">
          <el-form-item label="Employee">
            <el-select v-model="settings.staff" placeholder="Select Employee">
              <el-option value="0" label="All"></el-option>
              <el-option v-for="person in personnel" :key="person.em_id" :label="person.em_id_name" :value="person.em_id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Status">
            <el-select v-model="settings.status" placeholder="Select Approval Status">
              <el-option v-for="(stat, index) in statuses" :key="index" :value="stat.value" :label="stat.name"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button @click="closeFilterDialog()">Cancel</el-button>
          <el-button type="primary" @click="confirmFilterDialog()">Confirm</el-button>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="Approvers" :visible.sync="dialogTableVisible" :fullscreen="isMobile">
      <el-table :data="approvers">
        <el-table-column prop="hierarchy_no" width="50"></el-table-column>
        <el-table-column prop="boss_em_id_name" label="NAME">
          <template slot-scope="props">
            <div v-if="props.row.boss_em_id === $store.getters.user.employeeId"><b>{{props.row.boss_em_id_name}} (Me)</b></div>
            <div v-else>{{props.row.boss_em_id_name}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="a_id_name" label="STATUS">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.a_id_name)">{{ props.row.a_id_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="a_date_action" label="DATE ACTIONED">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ formatDate(scope.row.a_date_action) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :title="modalTitle" :visible.sync="dialogDisapproveVisible">
      <el-form :model="form">
        <el-form-item label="Reason for disapproving">
          <el-input v-model="form.remarks" type="textarea" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal()">Cancel</el-button>
        <el-button type="primary" @click="update()">Confirm</el-button>
      </span>
    </el-dialog>
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">HOLIDAY REQUEST APPROVAL</h3>
        <el-button type="primary" icon="el-icon-user" @click="drawerFilterVisible = true">Search Filter</el-button>
      </div>
      <div class="controls">
        <div class="control">
          <p>Items per page: </p>
          <el-select v-model="items">
            <el-option :label="5" :value="5"></el-option>
            <el-option :label="10" :value="10"></el-option>
            <el-option :label="25" :value="25"></el-option>
            <el-option :label="50" :value="50"></el-option>
            <el-option :label="100" :value="100"></el-option>
          </el-select>
        </div>
        <div class="control">
            <el-button-group>
              <el-button type="primary" icon="el-icon-arrow-left" @click="prevPage" :disabled="!hasPreviousPage">Previous Page</el-button>
              <el-button type="primary" @click="nextPage" :disabled="!hasNextPage">Next Page<i class="el-icon-arrow-right el-icon-right"></i></el-button>
            </el-button-group>
        </div>
      </div>
      <el-collapse accordion class="hidden-md-and-up">
        <el-collapse-item v-for="(file, index) in data" :key="index" :name="file.id">
          <template slot="title">
            <p style="width: 100%">{{ file.name }} - <el-tag :type="getStatusType(file.a_id_name)">{{ file.a_id_name }}</el-tag></p>
          </template>
          <p>Date Covered: <b>{{ file.holiday_date }}</b></p>
          <p>Requested By: <b>{{ file.em_id_name }}</b></p>
          <p>Location: <b>{{ file.bbl_id_name }}</b></p>
          <div class="collapse-buttons">
            <el-tooltip class="item" effect="dark" content="Approval Status" placement="top-start">
              <el-button el-button size="mini" type="primary" @click="showApprovalStatus(file.approvers)"><i class="el-icon-document-checked"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Approve" placement="top-start">
              <el-button size="mini" type="success" @click="showRemarksField(file, 2, 'Form Approved', 'Approving Form')" v-if="isCurrentApprover(file.approvers)"><i class="el-icon-check"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Disapprove" placement="top-start">
              <el-button size="mini" type="danger" @click="showRemarksField(file, 3, 'Form Disapproved', 'Disapproving Form')" v-if="isCurrentApprover(file.approvers)"><i class="el-icon-close"></i></el-button>
            </el-tooltip>
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-table :data="data" border class="hidden-sm-and-down">
        <el-table-column label="REQUESTED BY" prop="em_id_name" width="200"></el-table-column>
        <el-table-column label="HOLIDAY TYPE" prop="bg4_id_holidaytype_name" width="200"></el-table-column>
        <el-table-column label="DATE" prop="holiday_date" width="200"></el-table-column>
        <el-table-column label="NAME" prop="name" width="200"></el-table-column>
        <el-table-column label="LOCATION" prop="bbl_id_name" width="200"></el-table-column>
        <el-table-column label="STATUS" prop="a_id_name" min-width="400">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.a_id_name)">{{ props.row.a_id_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" fixed="right" width="180">
          <template slot-scope="scope">
            <div class="fixed-row">
              <el-tooltip class="item" effect="dark" content="Approval Status" placement="top-start">
                <el-button el-button size="mini" type="primary" @click="showApprovalStatus(scope.row.approvers)"><i class="el-icon-document-checked"></i></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Approve" placement="top-start">
                <el-button size="mini" type="success" @click="showRemarksField(scope.row, 2, 'Form Approved', 'Approving Form')" v-if="isCurrentApprover(scope.row.approvers)"><i class="el-icon-check"></i></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Disapprove" placement="top-start">
                <el-button size="mini" type="danger" @click="showRemarksField(scope.row, 3, 'Form Disapproved', 'Disapproving Form')" v-if="isCurrentApprover(scope.row.approvers)"><i class="el-icon-close"></i></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'Holiday',
  data () {
    return {
      windowWidth: window.innerWidth,
      items: 5,
      page: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      data: [],
      holidayTypes: [],
      locations: [],
      approvers: [],
      personnel: [],
      drawerFilterVisible: false,
      dialogTableVisible: false,
      dialogDisapproveVisible: false,
      file: null,
      statusId: null,
      snackbarResponse: null,
      modalTitle: null,
      statuses: [
        {
          value: 0,
          name: 'All'
        },
        {
          value: 1,
          name: 'Waiting'
        },
        {
          value: 2,
          name: 'Approved'
        },
        {
          value: 3,
          name: 'Disapproved'
        }
      ],
      form: {
        remarks: null
      },
      settings: {
        staff: null,
        status: null
      },
      rules: {
        name: [
          { required: true, message: 'Please type in a holiday name!', trigger: 'change' }
        ],
        holiday_date: [
          { required: true, message: 'Please choose a date', trigger: 'change' }
        ],
        bbl_id: [
          { required: true, message: 'Please choose a location!', trigger: 'change' }
        ],
        bg4_id_holidaytype: [
          { required: true, message: 'Please choose a holiday type!', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    isMobile () {
      return this.windowWidth < 992
    }
  },
  mounted () {
    this.getPersonnels()
    this.getHolidayRequests()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    getHolidayRequests () {
      const status = this.settings.status !== null ? this.settings.status : 1
      const staff = this.settings.staff !== null ? this.settings.staff : 0
      this.$http
        .get(`mss.holiday/list/${this.$store.getters.user.employeeId}/${status}/${staff}/${this.items}?page=${this.page}`)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body.data : []
          this.hasNextPage = res.data.StatusCode ? !!res.data.body.next_page_url : false
          this.hasPreviousPage = res.data.StatusCode ? !!res.data.body.prev_page_url : false
        })
    },
    nextPage () {
      this.page++
      this.getHolidayRequests()
    },
    prevPage () {
      this.page--
      this.getHolidayRequests()
    },
    update () {
      const currentApprover = this.file.approvers.find(approver => parseInt(approver.boss_em_id) === parseInt(this.$store.getters.user.employeeId))
      let nextApprover = null
      if (parseInt(currentApprover.hierarchy_no) !== 1) {
        nextApprover = this.file.approvers.find(approver => parseInt(approver.hierarchy_no) === parseInt(currentApprover.hierarchy_no) - 1)
      } else {
        nextApprover = {
          id: 1
        }
      }
      const form = {
        id: this.file.id,
        bft_id: 0,
        total_number_file: 0,
        esa_id: currentApprover.id,
        hierarchy_no: currentApprover.hierarchy_no,
        next_id: nextApprover.id,
        a_id: this.statusId,
        a_id_remark: this.form.remarks
      }
      this.form.remarks = null
      this.$http
        .post('mss.holiday/approval', form)
        .then(res => {
          this.$message({
            type: 'success',
            message: this.snackbarResponse
          })
          this.closeModal()
          this.getHolidayRequests()
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    closeModal () {
      this.file = null
      this.statusId = null
      this.snackbarResponse = null
      this.modalTitle = null
      this.dialogDisapproveVisible = false
      this.form.remarks = null
    },
    closeFilterDialog () {
      this.drawerFilterVisible = false
      this.settings.staff = null
      this.settings.status = null
    },
    confirmFilterDialog () {
      this.drawerFilterVisible = false
      this.getHolidayRequests()
    },
    showRemarksField (file, statusId, snackbarResponse, modalTitle) {
      this.file = file
      this.statusId = statusId
      this.snackbarResponse = snackbarResponse
      this.modalTitle = modalTitle
      if (statusId === 3) {
        this.dialogDisapproveVisible = true
      } else {
        this.$confirm('Are you sure you want approve this form?', 'Confirm', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'info'
        }).then(() => {
          this.update()
        }).catch((e) => {
          this.$message({
            type: 'info',
            message: 'Deletion cancelled'
          })
        })
      }
    },
    showDialog () {
      this.dialogCreateHoliday = true
    },
    cancel () {
      this.dialogCreateHoliday = false
    },
    getPersonnels () {
      this.$http
        .get(`mss.personnels-list/${this.$store.getters.user.employeeId}`)
        .then(res => {
          this.personnel = res.data.body
        })
    },
    isCurrentApprover (approvers) {
      const approver = approvers.find(approver => parseInt(approver.boss_em_id) === parseInt(this.$store.getters.user.employeeId))
      return parseInt(approver.is_action_taken)
    },
    showApprovalStatus (approvers) {
      this.approvers = approvers
      this.dialogTableVisible = true
    },
    getStatusType (type) {
      if (type === 'Waiting') {
        return 'primary'
      } else if (type === 'Approved') {
        return 'success'
      } else {
        return 'danger'
      }
    },
    formatDate (date) {
      if (date) {
        return format(new Date(date), 'iiii, PPP')
      } else {
        return ''
      }
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.onResize)
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-table {
    overflow: auto;
  }
  .expanded-p {
    margin: 0;
  }
  .fixed-row {
    display: flex;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
